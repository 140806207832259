import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { SelectionService } from "@modules/core/services/selection.service";
import { MentionModel, MentionType } from "@types";
import { sorted } from "@utils";
import deepEqual from "deep-equal";
import { BehaviorSubject, Subject, Subscription, debounceTime, distinctUntilChanged, fromEvent } from "rxjs";
import {
  CreateEditMessageModelParams,
  EditMessageModel,
  EditMessageModelAction,
  EditMessageModelState,
  EditMessageModelUpdateReason,
  UpdateEditMessageModelParams
} from "./EditMessageModel";
import { ValidationError } from "./ValidationError";

export interface MessageComposeModel {
  content: string;
  mentions?: MentionModel[];
}

export enum MessageNodeType {
  Text = "Text",
  Mention = "Mention",
}

export interface MessageNodeBase {
  type: MessageNodeType;
}

export interface MessageTextSpan extends MessageNodeBase {
  type: MessageNodeType.Text;
  text: string;
}

export interface MessageMentionSpan extends MessageNodeBase {
  type: MessageNodeType.Mention;
  mention: MentionModel;
}

export type MessageNode = MessageTextSpan | MessageMentionSpan;

export const renderNodes = (message: MessageComposeModel): MessageNode[] => {
  if (!message.content.length && !message.mentions?.length) return [];

  const sortedMentions = message.mentions
    ? sorted(message.mentions, (a, b) => a.index - b.index)
    : [];

  const nodes: MessageNode[] = [];

  let previousMentionEndIndex: number | null = null;
  for (const mention of sortedMentions) {
    if (previousMentionEndIndex === null) previousMentionEndIndex = 0;

    if (previousMentionEndIndex < mention.index) {
      const textBeforeMention = message.content.substring(
        previousMentionEndIndex,
        mention.index
      );

      nodes.push({
        type: MessageNodeType.Text,
        text: textBeforeMention,
      });
    }

    nodes.push({
      type: MessageNodeType.Mention,
      mention,
    });

    previousMentionEndIndex = mention.index + mention.name.length;
  }

  if (
    previousMentionEndIndex === null ||
    previousMentionEndIndex < message.content.length
  ) {
    nodes.push({
      type: MessageNodeType.Text,
      text: message.content.substring(previousMentionEndIndex),
    });
  }

  return nodes;
};

enum DOMNodeType {
  ELEMENT_NODE = 1,
  ATTRIBUTE_NODE = 2,
  TEXT_NODE = 3,
  CDATA_SECTION_NODE = 4,
  PROCESSING_INSTRUCTION_NODE = 7,
  COMMENT_NODE = 8,
  DOCUMENT_NODE = 9,
  DOCUMENT_TYPE_NODE = 10,
  DOCUMENT_FRAGMENT_NODE = 11,
}

export enum MessageInputErrorCode {
  MAX_LENGTH_EXCEEDED = 'MAX_LENGTH_EXCEEDED',
}

const IGNORED_KEYUP_EVENT_KEYS = new Set(['Control', 'Meta', 'Alt', 'Shift']);

// Note: implement ControlValueAccessor to make this compatible with Angular forms
@Component({
  selector: "app-message-input",
  templateUrl: "./message-input.component.html",
  styleUrls: ["./message-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public placeholder: string = "Type a message";

  @Input()
  public initialValue: MessageComposeModel | null = null;

  @Input()
  public maxLength: number = 3000;

  @Input()
  public maxHeight: string = "200px";

  @Input()
  public disableSubmit: boolean = false;

  /** Debounce time for saving changes to the undo/redo stack in milliseconds. */
  @Input()
  public saveChangesDebounceDelay: number = 150;

  @Input()
  public spellcheckDebounceDelay: number = 500;

  @Output()
  public submitEvent = new EventEmitter<MessageComposeModel>();

  @Output()
  public changeEvent = new EventEmitter<MessageComposeModel>();

  @Output()
  public pasteEvent = new EventEmitter<ClipboardEvent>();

  @Output()
  public actionEvent = new EventEmitter<EditMessageModelAction | null>();

  @Output()
  public userInputEvent = new EventEmitter<void>();

  private validationErrorSubject = new BehaviorSubject<ValidationError | null>(null);

  /**
   * Only emits if there is a validation error (e.g. max length being exceeded).
   * `null` is emited after all validation errors have been resolved.
   */
  @Output()
  public validationEvent = this.validationErrorSubject.asObservable();

  @ViewChild("input", { static: true })
  private inputElement: ElementRef<HTMLDivElement> | null = null;

  public isFocused: boolean = false;

  private editMessageModel: EditMessageModel;
  private editMessageModelSubscription: Subscription | null = null;

  private mutationObserver: MutationObserver | null = null;

  private lastCaretPosition: number = 0;

  private blurEventSubscription: Subscription | null = null;

  private contentChangedSubject: Subject<void> = new Subject();

  private ignoreNextKeyUpKeys: Set<string> = new Set<string>()

  public constructor(
    private renderer: Renderer2,
    private selectionService: SelectionService
  ) { }

  public ngOnInit(): void {
    let editMessageModelParams: CreateEditMessageModelParams | null = null;
    if (this.initialValue) {
      editMessageModelParams = {
        content: this.initialValue.content,
        mentions: this.initialValue.mentions,
      };
    }

    this.editMessageModel = new EditMessageModel(editMessageModelParams);

    this.editMessageModelSubscription = this.editMessageModel.state$
      .pipe(
        distinctUntilChanged((previous, current) => {
          // Only re-render if something other than the caret position has changed
          const { caretPosition: a, ...previousStateWithoutCaretPosition } =
            previous;
          const { caretPosition: b, ...currentStateWithoutCaretPostion } =
            current;
          return deepEqual(
            previousStateWithoutCaretPosition,
            currentStateWithoutCaretPostion,
            {
              strict: true,
            }
          );
        })
      )
      .subscribe({
        next: (state) => {
          if (this.shouldRender(state)) {
            this.render();
          }
          this.validate();
          this.changeEvent.emit(this.getMessageComposeModel());
          this.actionEvent.next(state.action);
          this.lastCaretPosition = state.caretPosition;
        },
      });

    this.contentChangedSubject.pipe(debounceTime(this.saveChangesDebounceDelay)).subscribe({
      next: () => {
        this.saveChanges();
      }
    })
  }

  public ngAfterViewInit(): void {
    const element = this.inputElement.nativeElement;
    this.blurEventSubscription = fromEvent(element, 'blur')
      .subscribe({
        next: () => {
          this.isFocused = false;
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.editMessageModelSubscription) {
      this.editMessageModelSubscription.unsubscribe();
    }

    if (this.blurEventSubscription) {
      this.blurEventSubscription.unsubscribe();
    }

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  public insertMention(mention: MentionModel) {
    const caretPosition = this.getCaretPosition();
    this.editMessageModel.insertMention(caretPosition, mention);
    this.saveChanges();
  }

  public insertText(text: string) {
    const caretPosition = this.getCaretPosition();
    this.editMessageModel.insertText(caretPosition, caretPosition, text);
    this.saveChanges();
  }

  public restoreFocus() {
    this.setCaretPosition(this.lastCaretPosition);
  }

  public handleInput(event: InputEvent) {
    this.isFocused = true;
    this.updateContent();
  }

  public handleKeydown(event: KeyboardEvent) {
    this.userInputEvent.next();

    this.isFocused = true;

    // Note metaKey is the equivalent of control on some operating systems, e.g. 'command' on macOS
    const isCtrlOrMetaKeyPressed = event.ctrlKey || event.metaKey;
    const lowerCaseKey = event.key.toLowerCase();

    // Disable content editable's default handling for bold and italic
    if (isCtrlOrMetaKeyPressed && !event.shiftKey) {
      switch (lowerCaseKey) {
        case "b":
          event.preventDefault();
          return;
        case "i":
          event.preventDefault();
          return;
        case 'a':
          this.ignoreNextKeyUpKeys.add(event.key);
          return;
      }
    }

    if (isCtrlOrMetaKeyPressed && lowerCaseKey === 'v') {
      this.ignoreNextKeyUpKeys.add(event.key);
      return;
    }

    // Disable default undo and redo behavior as it will not work due to how we manipulate the DOM
    let isUndo: boolean = false;
    let isRedo: boolean = false;

    if (isCtrlOrMetaKeyPressed) {
      if ((event.shiftKey && lowerCaseKey == 'z') || (!event.shiftKey && lowerCaseKey === 'y')) {
        isRedo = true
      } else if (lowerCaseKey === 'z') {
        isUndo = true;
      }
    }

    if (isUndo || isRedo) {
      event.preventDefault();

      if (isUndo) {
        this.editMessageModel.undo();
      } else {
        this.editMessageModel.redo();
      }
      return;
    }

    // Submit if Enter (without Shift) is pressed
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (!this.disableSubmit) this.submit()
      return;
    }

    const input = this.inputElement.nativeElement;
    const range = this.getSelectedRange();

    const isInput = range.endContainer === input;
    const isEndOfTextNode =
      range.endContainer.nodeType === DOMNodeType.TEXT_NODE &&
      range.endOffset === range.endContainer.textContent.length;
    const isStartOfTextNode =
      range.endContainer.nodeType === DOMNodeType.TEXT_NODE && range.startOffset === 0;

    if (!isInput && !isEndOfTextNode && !isStartOfTextNode) {
      if (event.repeat) this.actionEvent.next(null);
      return;
    };

    let nextNode: Node;

    if (!event.shiftKey && (event.key === "ArrowLeft" || event.key === "ArrowRight")) {
      switch (event.key) {
        case "ArrowLeft":
          if (!isInput && !isStartOfTextNode) break;
          nextNode = isInput
            ? input.childNodes[range.startOffset - 1]
            : range.endContainer.previousSibling;
          if (!nextNode || nextNode.nodeType === DOMNodeType.TEXT_NODE) break;
          event.preventDefault();
          range.setStartBefore(nextNode);
          range.collapse(true);
          this.ignoreNextKeyUpKeys.add(event.key);
          break;
        case "ArrowRight":
          if (!isInput && !isEndOfTextNode) break;
          nextNode = isInput
            ? input.childNodes[range.endOffset]
            : range.endContainer.nextSibling;
          if (!nextNode || nextNode.nodeType === DOMNodeType.TEXT_NODE) break;
          event.preventDefault();
          range.setStartAfter(nextNode);
          range.collapse(true);
          this.ignoreNextKeyUpKeys.add(event.key);
          break;
      }
      if (event.repeat) this.actionEvent.next(null);
      return;
    }
  }

  public handleKeyup(event: KeyboardEvent) {
    this.isFocused = true;

    if (IGNORED_KEYUP_EVENT_KEYS.has(event.key)) return;

    if (this.ignoreNextKeyUpKeys.has(event.key)) {
      this.ignoreNextKeyUpKeys.delete(event.key);
      return;
    }

    this.updateCaretPosition();
  }

  public handlePaste(event: ClipboardEvent) {
    event.preventDefault();

    this.userInputEvent.next();

    this.isFocused = true;

    // Prefer the text representation of content on the clipboard
    const text = event.clipboardData.getData('text/plain');

    if (text) {
      const range = this.selectionService.getRange();
      const selectionLength = range.toString().length
      const endOffset = this.getCaretPosition();
      this.editMessageModel.insertText(endOffset - selectionLength, endOffset, text);
    }

    this.pasteEvent.emit(event);
    this.updateContent(true);
  }

  public handleClick(event: MouseEvent) {
    this.isFocused = true;
    this.updateCaretPosition();
  }

  public submit() {
    this.validate();
    if (this.validationErrorSubject.getValue()) return;

    const message = this.getMessageComposeModel();

    if (!message.content.trim().length) {
      this.clear();
      return;
    }

    this.submitEvent.emit(message);

    this.clear();
  }

  public reset(message?: MessageComposeModel | null) {
    let updateEditMessageModelParams: UpdateEditMessageModelParams | null = null;

    if (message) {
      updateEditMessageModelParams = {
        content: message.content,
        mentions: message.mentions,
        caretPosition: message.content.length
      }
    }

    this.editMessageModel.reset(updateEditMessageModelParams)

    this.inputElement.nativeElement.focus();
  }

  private saveChanges() {
    this.editMessageModel.save();
  }

  private validate() {
    const state = this.editMessageModel.getState();
    if (state.content.length > this.maxLength) {
      const validationError = new ValidationError(MessageInputErrorCode.MAX_LENGTH_EXCEEDED);
      this.validationErrorSubject.next(validationError);
    } else {
      this.validationErrorSubject.next(null);
    }
  }

  private getMessageComposeModel() {
    const state = this.editMessageModel.getState();
    const messageComposeModel: MessageComposeModel = {
      content: state.content,
      mentions: state.mentions
    }
    return messageComposeModel;
  }

  private clear() {
    this.editMessageModel.reset();
  }

  private getSelectedRange() {
    return this.selectionService.getRange();
  }

  private getCaretPosition() {
    const input = this.inputElement.nativeElement;
    if (!this.isFocused) return this.lastCaretPosition;
    return this.selectionService.getSelectionLength(input);
  }

  private setCaretPosition(offset: number) {
    const element = this.inputElement.nativeElement;

    if (offset === 0) {
      this.selectionService.setSelection({
        startContainer: element,
        startOffset: 0,
      });
      return;
    }

    let totalOffset = 0;
    let node = element.firstChild;
    while (node) {
      const nextOffset = totalOffset + node.textContent.length;
      if (nextOffset >= offset) break;
      totalOffset = nextOffset;
      if (!node.nextSibling) break;
      node = node.nextSibling;
    }

    const containerOffset = offset - totalOffset;
    const isEndOfNode = containerOffset === node.textContent.length;

    if (isEndOfNode || node.nodeType !== DOMNodeType.TEXT_NODE) {
      this.selectionService.setSelectionAfter(node);
    } else {
      this.selectionService.setSelection({
        startContainer: node,
        startOffset: containerOffset,
      });
    }

    element.focus();
  }

  private isSameNodeOrChildOfNode = (parentNode: Node, otherNode: Node) => {
    return parentNode === otherNode || otherNode.parentNode === parentNode;
  }

  private handleMentionMouseUp = (element: HTMLElement) => {
    const range = this.selectionService.getRange(0);
    if (!range) return;

    if (!this.isSameNodeOrChildOfNode(element, range.commonAncestorContainer)) return;

    this.selectionService.selectNode(element);
  }

  private shouldRender(newState: EditMessageModelState) {
    const { updateReason } = newState;
    if (updateReason === EditMessageModelUpdateReason.Update) return false;
    if (updateReason === EditMessageModelUpdateReason.UpdateCaretPosition) return false;
    return true;
  }

  private render() {
    const state = this.editMessageModel.getState();
    const input = this.inputElement.nativeElement;

    const nodes = renderNodes({
      content: state.content,
      mentions: state.mentions,
    });

    while (input.firstChild) {
      input.removeChild(input.firstChild);
    }

    for (const node of nodes) {
      switch (node.type) {
        case MessageNodeType.Text:
          this.renderer.appendChild(input, this.renderer.createText(node.text));
          break;
        case MessageNodeType.Mention:
          const span = this.renderer.createElement("span");
          const textNode = this.renderer.createText(node.mention.name);
          this.renderer.appendChild(span, textNode);
          this.renderer.addClass(span, "mention");
          this.renderer.setAttribute(span, "contenteditable", "false");
          this.renderer.setAttribute(
            span,
            "data-type",
            MessageNodeType.Mention
          );
          this.renderer.setAttribute(span, "data-id", node.mention.id);
          this.renderer.listen(span, 'mouseup', () => this.handleMentionMouseUp(span));
          this.renderer.appendChild(input, span);
          break;
        default:
          console.error("Unhandled message node:", node);
      }
    }

    this.setCaretPosition(state.caretPosition);
  }

  private parseInputHtml() {
    const input = this.inputElement.nativeElement;

    let currentNode: Node = input.firstChild;
    let textNode: Node = currentNode;
    let totalOffset = 0;

    let message: Required<MessageComposeModel> = {
      content: "",
      mentions: [],
    };

    while (currentNode) {
      textNode = currentNode;
      if (
        (currentNode as HTMLElement).dataset?.type === MessageNodeType.Mention
      ) {
        textNode = currentNode.firstChild;
        const id = (currentNode as HTMLElement).dataset.id;
        message.mentions.push({
          id,
          index: totalOffset,
          name: textNode.textContent,
          type: MentionType.User,
        });
      }

      message.content += textNode.textContent;
      totalOffset += textNode.textContent.length;
      currentNode = currentNode.nextSibling;
    }

    return message;
  }

  private updateContent(forceLastCaretPosition: boolean = false) {
    const caretPosition = forceLastCaretPosition ? this.lastCaretPosition : this.getCaretPosition();
    const message = this.parseInputHtml();
    this.editMessageModel.update({
      ...message,
      caretPosition,
    });
    this.contentChangedSubject.next();
  }

  private updateCaretPosition() {
    const caretPosition = this.getCaretPosition();
    this.lastCaretPosition = caretPosition;

    // If this is a selection of one or more characters then don't update the caret position
    // this is because we don't want to trigger mentions or any actions when a user is just
    // selecting something
    const range = this.selectionService.getRange();
    if (!range.collapsed) return;

    this.editMessageModel.updateCaretPosition(caretPosition);
  }
}
