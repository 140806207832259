import { Subscription } from "rxjs";
import { Component } from "@angular/core";
import {
  AuthService,
} from "@modules/core";

@Component({
  selector: "app-register-login-oidc",
  templateUrl: "./register-login-oidc.component.html",
  styleUrls: ["./register-login-oidc.component.scss"],
})
export class RegisterLoginOidcComponent {
  subscription: Subscription | null = null;

  constructor(
    private authService: AuthService,
  ) {
    this.authService.loginTwo()
  }

}
