<div #bar class="bar">
  <div class="box1 relative">
    <div class="attach-button">
      <button
        id="attach-files"
        mat-icon-button
        class="celo-hoverable"
        (click)="attachFiles.emit(); attachButton()"
        matTooltip="Attach"
        [disabled]="quotedMessage ? true : false"
        [disableRipple]="true"
      >
        <img src="../../../assets/icons/icon-attach.svg" />
      </button>
    </div>
    <div class="attach-button">
      <button
        id="attach-secure-library"
        mat-icon-button
        class="celo-hoverable"
        (click)="addPhotos.emit(); secureLibraryButton()"
        matTooltip="Secure Library"
        [disabled]="quotedMessage ? true : false"
        [disableRipple]="true"
      >
        <img src="../../../assets/icons/icon-secure-library.svg" />
      </button>
    </div>
    <div class="attach-button">
      <button
        id="attach-upload-photo"
        mat-icon-button
        class="celo-hoverable"
        (click)="photoSelect.click(); uploadButton()"
        matTooltip="Upload Photo"
        [disabled]="quotedMessage ? true : false"
      >
        <img src="../../../assets/icons/icon-photos.png" />
      </button>
      <input
        [multiple]="true"
        accept="image/png, image/jpeg"
        #photoSelect
        class="d-none"
        type="file"
        id="celo-textbar"
        ng2FileSelect
        [uploader]="uploader"
      />
    </div>
    <div class="attach-button emoji-container">
      <div *ngIf="emojiOpen" class="emoji-box emoji-anim">
        <emoji-mart
          [style]="{
            position: 'absolute',
            bottom: bar.offsetHeight + 10 + 'px',
            left: '10px'
          }"
          [darkMode]="false"
          (emojiClick)="addEmoji($event)"
          [emojiSize]="34"
          [showPreview]="false"
        ></emoji-mart>
      </div>
      <button
        id="emoji-button"
        mat-icon-button
        class="celo-hoverable"
        (click)="emojiOpen = !emojiOpen; emojiOpen ? emojiButton() : ''"
      >
        <i class="material-icons">{{
          emojiOpen ? "arrow_drop_down" : "sentiment_satisfied_alt"
        }}</i>
      </button>
    </div>
  </div>
  <div class="chat-area box" [class.invalid]="!isValid">
    <div
      class="quote-container open-slow"
      [style.height.px]="quotedMessage ? 80 : 0"
    >
      <div #quote class="quote">
        <div class="quote-content" *ngIf="quotedMessage">
          <app-quoted-message
            [message]="quotedMessage"
            [userAccount]="userAccount"
            [participantAccount]="pMap[quotedMessage.sentBy]"
            [collapsible]="true"
          ></app-quoted-message>
        </div>
      </div>
      <div class="close-button">
        <button mat-icon-button (click)="cancelQuote.emit()">
          <i class="material-icons">cancel</i>
        </button>
      </div>
    </div>
    <app-message-compose-v2
      #messageCompose
      [initialValue]="initialValue"
      [userId]="userAccount.userId"
      [participants]="participants"
      [maxLength]="maxLength"
      (submitEvent)="handleSubmit($event)"
      (changeEvent)="handleChange($event)"
      (pasteEvent)="handlePaste($event)"
      (userInputEvent)="handleUserInput()"
      (validationEvent)="handleValidationError($event)"
    ></app-message-compose-v2>
  </div>
  <div
    [matTooltipDisabled]="isValid"
    [matTooltip]="submitTooltip"
    showDelay="0"
    matTooltipPosition="above"
    aria-label="Submit"
  >
    <button
      mat-button
      class="send"
      (click)="messageCompose.submit()"
      [disabled]="!isValid"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
